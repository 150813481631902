.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  margin-bottom: 50px;
}

.card-header, .post-text {
  text-align: left;
}

.card-title {
  text-align: left;
  font-size: 2em;
}

.post {
  padding: 10px 20px 10px 20px;
}
